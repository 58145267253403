<template>
      <b-form v-if="show" id="modalUpdate" action="#" @submit.prevent="saveButton">
        <!--
          ID, System ID, System, Token ID, Token Name, CP ID, Car Park, Mfg No., Card No., Method, Entry Lane, Entry Date, Exit Lane, Exit Date,  Amount, Amount (SST), Net Amount, Created Date, Sync Date
         -->
         <b-row>
              <b-col md="12" class="text-left">
                  <b-form-group label="System">
                      <v-select v-model="selected" placeholder="-- All --" :options="system" required @input="filterCarPark"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="12" class="text-left">
                  <b-form-group label="Car Park">
                    <v-select :key="count" v-model="selectedCP" placeholder="-- All --" :options="carpark" required :disabled="disabledCP"></v-select>
                  </b-form-group>
              </b-col>
          </b-row>
        <b-row>
          <b-col sm="12">
            <b-form-group label="Token Name:">
              <b-form-input
                type="text"
                v-model="name.item"
                required
                placeholder="" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="text-left">
                <b-button-group>
                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Save</b-button>
                </b-button-group>
            </b-col>
        </b-row>
      </b-form>
</template>

<script>
import vSelect from 'vue-select'
  export default {
    name: "UpdateTokenListing",
    props: ['modalUpdate'],

    data() {
      return {
        form: {
          email: '',
          name: '',
          address: '',
          country: null,
          checked: '',
          pwd: '',
        },
        show: true,
        selected: {},
        selectedCP: {},
        selectedTK: {},
        system: JSON.parse(this.modalUpdate.system),
        carpark: JSON.parse(this.modalUpdate.carpark),
        disabledCP: true,
        disabledTK: true,
        lanecode: {item: ''},
        name: {item: ''},
        count: 0,
        count2: 0
      }
    },

    components: {
        'v-select': vSelect,
    },

    async created(){
        this.selected.code = JSON.parse(this.modalUpdate.content).systemID;
        this.selected.label = JSON.parse(this.modalUpdate.content).systemLabel;

        let item = {};
        item.code = JSON.parse(this.modalUpdate.content).systemID;
        await this.listCarParkBySystem(item, 1);
        this.name.item = JSON.parse(this.modalUpdate.content).name;
        this.lanecode.item = JSON.parse(this.modalUpdate.content).laneCode;
        this.selectedCP.code = JSON.parse(this.modalUpdate.content).carparkID;
        this.selectedCP.label = JSON.parse(this.modalUpdate.content).carparkID + ' - ' +JSON.parse(this.modalUpdate.content).carParkName;

        this.selectedTK.code = JSON.parse(this.modalUpdate.content).tokenID;
        this.selectedTK.label = JSON.parse(this.modalUpdate.content).tokenID + ' - ' +JSON.parse(this.modalUpdate.content).carparkID;
        this.count++;
        this.count2++;
    },

    methods: {
        filterCarPark(){
            this.listCarParkBySystem(this.selected, 1);
        },

        resetSearch(){
            //this.selectedSystem = '';
            this.disabledCP = true;
            this.disabledTK = true;
            this.carpark = [];
            this.selectedCP = {};
            this.selectedTK = {};
        },

        async listCarParkBySystem(value, pg){
            var cd = value != null ? value.code : '';
            this.resetSearch();
            console.log('item', cd);

            try{
                let response = await this.$store.dispatch("getCarPark", {sel_system: cd})
                if (response){
                    //this.system.push(response.data.result);
                    let dt = response.data.result;
                    let ndt = [];

                    for(var i=0; i<dt.length; i++){
                        //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                        ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
                    }

                    this.carpark = ndt;

                    if (response.data.count > 0){
                        console.log('hide');
                        this.disabledCP = false;
                        this.disabledTK = false;
                    }
                }
            }

            catch(err){
                console.log(err);
            }
        },

        saveButton(){
            let items = {};
            items.name = this.name.item;
            items.system = this.selected.code;
            items.carpark = this.selectedCP.code;
            items.tokenID = this.selectedTK.code;

            this.$swal({
                title: 'Update Token Details',
                text: "Are you sure you want to update this token?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, please!'  
            }).then((result) => {
                if (result.value){
                this.$emit('confirm', items);
                }
            })
        }
    }
  }
</script>