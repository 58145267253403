<template>
<b-row>
    <b-col cols="12">
      <transition name="slide">
        <b-card>
            <b-row>
                <b-col class="text-left">
                <h5 class="mb-3 text-uppercase text-grey">Lane Mapping</h5>
                </b-col>
            </b-row>
            <div style="display: block;
    width: 100%;">
        <b-form>
          <b-row>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="System">
                      <v-select v-model="selected" placeholder="-- All --" :options="system" @input="listCarParkBySystem(selected, 1)" :disabled="disabledSys"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Car Park">
                    <v-select v-model="selectedCP" placeholder="-- All --" :options="carpark" @input="listAllTxn(1)" :disabled="disabledCP"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Search">
                          <b-form-input v-model="txn_search" @keyup="searchTimeOut"></b-form-input>
                  </b-form-group>
              </b-col>
          </b-row>
        </b-form>
            <b-pagination
                v-model="currentPage"
                :total-rows.sync="totalItems"
                :per-page="perPage"
                prev-text="Prev"
                next-text="Next"
                class="mt-4"
            >
            </b-pagination>
            <b-row>
                <b-col md="12" class="text-left">
                  <b-button-group>
                    <b-button type="submit" variant="primary" @click="createscreen($event.target)"><i class="fa fa-sync"></i> Create Lane</b-button>
                  </b-button-group>
              </b-col>
            </b-row>
            <b-table hover 
            class="mt-3"
            :items="items"
            :fields="valfields"
            show-empty
            small
            responsive
            bordered
            striped
            :busy="isBusy"
            :filter="filter"
            :per-page="0"
            :current-page="currentPage"
            >
            <template v-slot:cell(actions)="row">
              <b-button v-b-tooltip.hover title="View Details" pill size="sm" variant="success" @click="info(row.item, row.index, $event.target)" class="mr-1">
               <i class="fa fa-search"></i>
              </b-button>
            </template>
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
                </div>
            </template>
            <template v-slot:cell(createDate)="row">
                <div class="text-center">
                {{row.item.createDate}}
                </div>
            </template>
            <template v-slot:cell(updateDate)="row">
                <div class="text-center">
                {{row.item.updateDate}}
                </div>
            </template>
            </b-table>
            </div>
        </b-card>
      </transition>
    </b-col>
    <b-modal id="modalInfo" size="lg" title="Create Lane" hide-footer>
      <!-- <pre>{{ modalInfo.content }}</pre> -->
      <v-create :modalInfo="modalInfo" @confirm="onConfirm"></v-create>
    </b-modal>
    <b-modal id="modalUpdate" size="lg" title="Update Lane" hide-footer>
      <!-- <pre>{{ modalInfo.content }}</pre> -->
      <v-update :modalUpdate="modalUpdate" @confirm="onConfirmEdit"></v-update>
    </b-modal>
</b-row>
</template>

<script>
import timer from '../assets/js/sleeper';
import vSelect from 'vue-select'
import CreateLane from './CreateLane'
import UpdateLane from './UpdateLane'
import * as moment from "moment";

let items = [];
export default {
    data() {
      return {
        items: [],
        isBusy: false,
        filter: null,
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        /*
        ID, System, Token ID, CP ID, Car Park, Lane Code, Lane Name, Created Date, Updated Date
        */
        valfields: [
        { key: 'row', label: '#', class: 'text-nowrap' },
        { key: 'actions', label: 'Actions', class: 'text-nowrap' },
        { key: 'id', label: 'ID', class: 'text-nowrap' },
        { key: 'systemName', label: 'System', class: 'text-nowrap' },
        { key: 'tokenID', label: 'Token ID', class: 'text-nowrap' },
        //{ key: 'tokenName', label: 'Token', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'carparkID', label: 'CP ID', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'carParkName', label: 'Car Park', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'laneCode', label: 'Lane Code', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'laneName', label: 'Lane Name', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'remark', label: 'Method', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'EntryLane', label: 'Entry Lane', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'EntryTime', label: 'Entry Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'ExitLane', label: 'Exit Lane', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'ExitTime', label: 'Exit Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'Fare', label: 'Amount', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'FareSST', label: 'Amount (SST)', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'netAmount', label: 'Net Amount', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'createDate', label: 'Created Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'updateDate', label: 'Updated Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' }
      ],
      system: [],
      carpark: [],
      tokens: [],
      allcarpark: [],
      selected: '',
      selectedCP: '',
      disabledSys: true,
      disabledCP: true,
      modalInfo: { title: '', content: '{}', name: '' },
      modalUpdate: { title: '', content: '{}', name: '' },
      syncOptions: [
                    {code: "1", label: "Not Synchronized"}
                   ],
      txn_search: "",
      timeout: null,
      sort: 'createDate',
      }
    },

    components: {
        'v-select': vSelect,
        'v-create': CreateLane,
        'v-update': UpdateLane
    },

    async created(){
        await this.listSystem();
        await this.listAllCarPark();
        await this.listAllTokens();
        await this.listAllTxn();
        //this.listAllTokens();
        //this.listAllTxn();
    },

    watch: {
        currentPage: {
        handler: function(value) {
            if (this.currentPage != 1)
            {
                this.items = [];
                this.listAllTxn();
            }
        }
        },
    },

    methods: {
        async listSystem(){
            try{
                let response = await this.$store.dispatch("getSystem", {})

                if (response){
                    //this.system.push(response.data.result);
                    let dt = response.data.result;
                    let ndt = [];

                    for(var i=0; i<dt.length; i++){
                        //ndt.push({value: dt[i].systemID, text: dt[i].name});
                        ndt.push({code: dt[i].systemID, label: dt[i].systemID + ' - ' +dt[i].name, name: dt[i].name});
                    }

                    this.system = ndt;
                    this.disabledSys = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async listAllTokens(){
            try{
                let response = await this.$store.dispatch("getAllTokens", {sort: this.sort, active: 1})
                if (response){
                    //this.system.push(response.data.result);
                    let dt = response.data.result;
                    this.tokens = dt;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async listAllCarPark(){
            this.disabledCP = true;
            try{
                let response = await this.$store.dispatch("getAllCarPark", {})
                if (response){
                    //this.system.push(response.data.result);
                    let dt = response.data.result;
                    this.allcarpark = dt;

                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
                            }

                            this.carpark = ndt;
                }

                this.disabledCP = false;
            }

            catch(err){
                console.log(err);
            }
        },

        async listAllTxn(pg){
            if (this.currentPage >= 0 ){
            this.isBusy = true;

            try{
                let response = await this.$store.dispatch("getAllLaneMapping", {curPage: pg > 0 ? this.currentPage = pg : this.currentPage, systemID: this.selected == null ? "" : this.selected.code, carparkID: this.selectedCP == null ? "" : this.selectedCP.code, search: this.txn_search, sort: this.sort});
                    
                if (response){
                    let dt = response.data.result
                    this.isBusy = !this.isBusy

                    for(var i=0; i<dt.length; i++){
                        dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;

                        let b = this.allcarpark.find((item) => {
                            return item.carparkID == dt[i].carparkID;
                        });

                        dt[i].carParkName = b.carpark;

                        let fare = dt[i].Fare == null ? 0 : dt[i].Fare;
                        let faresst = dt[i].FareSST == null ? 0 : dt[i].FareSST;

                        let c = this.system.find((item) => {
                            return item.code == dt[i].systemID;
                        });

                        let d = this.tokens.find((item) => {
                            return item.tokenID == dt[i].tokenID;
                        });

                        let tokenName = d == undefined ? "N/A" : d.name;

                        dt[i].netAmount = fare - faresst;
                        dt[i].createDate = dt[i].createDate == null ? "N/A" : moment.unix(dt[i].createDate).format('DD-MM-YYYY hh:mm:ss A');
                        dt[i].updateDate = dt[i].updateDate == null ? "N/A" : moment.unix(dt[i].updateDate).format('DD-MM-YYYY hh:mm:ss A');
                        dt[i].systemName = c.name;
                        dt[i].systemLabel = c.label;
                        dt[i].tokenName = tokenName;
                    }

                    console.log(dt);

                    this.items = dt;

                    this.totalItems = response.data.count;
                }
            }

            catch(err){
                console.log(err);
            }
            }
        },

        info(item, index, button) {
            this.modalUpdate.title = `Row index: ${index}`
            this.modalUpdate.content = JSON.stringify(item)

            this.modalUpdate.system = JSON.stringify(this.system);

            let ndt = [];
            let dt = this.allcarpark;
            for(var i=0; i<dt.length; i++){
                //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
            }
            this.modalUpdate.carpark = JSON.stringify(ndt);

            let tokens = [];
            let dt2 = this.tokens;
            for(var i=0; i<dt2.length; i++){
                //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                tokens.push({code: dt2[i].tokenID, label: dt2[i].tokenID + ' - ' + dt2[i].carparkID, systemID: dt2[i].systemID});
            }
            this.modalUpdate.tokens = JSON.stringify(tokens);

            this.$root.$emit('bv::show::modal', 'modalUpdate', button)
        },

        createscreen(button) {
            this.modalInfo.title = `Create`;
            this.modalInfo.system = JSON.stringify(this.system);

            let ndt = [];
            let dt = this.allcarpark;
            for(var i=0; i<dt.length; i++){
                //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
            }
            this.modalInfo.carpark = JSON.stringify(ndt);

            let tokens = [];
            let dt2 = this.tokens;
            for(var i=0; i<dt2.length; i++){
                //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                tokens.push({code: dt2[i].tokenID, label: dt2[i].tokenID + ' - ' + dt2[i].carparkID, systemID: dt2[i].systemID});
            }
            this.modalInfo.tokens = JSON.stringify(tokens);

            this.$root.$emit('bv::show::modal', 'modalInfo', button)
        },

        listCarParkBySystem(value, pg){
            var cd = value != null ? value.code : '';
            this.resetSearch();

            //console.log(cd)
            this.$store.dispatch("getCarPark", {sel_system: cd})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
                            }

                            //this.system_name = this.system.filter(sys => sys.value === this.selected)[0].text;

                            this.listAllTxn(pg);

                            this.carpark = ndt;

                            if (response.data.count > 0)
                                this.disabledCP = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        async createLane(v){
            try{
                let req = {
                    "tokenID": v.tokens,
                    "laneName": v.lanename,
                    "laneCode": v.lanecode,
                    "carparkID": v.carpark
                };
                let response = await this.$store.dispatch("createLaneMapping", req);
                if (response){
                    await this.listAllTxn(1);
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async updateLane(v){
            try{
                let req = {
                    "id": JSON.parse(this.modalUpdate.content).id,
                    "tokenID": v.tokens,
                    "laneName": v.lanename,
                    "laneCode": v.lanecode,
                    "carparkID": v.carpark
                };
                let response = await this.$store.dispatch("updateLaneMapping", req);
                if (response){
                    await this.listAllTxn(1);
                }
            }

            catch(err){
                console.log(err);
            }
        },

        resetSearch(){
            //this.selectedSystem = '';
            this.disabledCP = true;
            this.carpark = [];
            this.selectedCP = '';
        },

        Unix_timestamp(t)
        {
            if (t != null){
                var unixtimestamp = t;
                var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var date = new Date(unixtimestamp*1000);
                var year = date.getFullYear();
                var month = months_arr[date.getMonth()];
                var day = date.getDate();
                var hours = date.getHours();
                var minutes = "0" + date.getMinutes();
                var seconds = "0" + date.getSeconds();
                var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            }

            if (t == null){
                var convdataTime = '';
            }

            return convdataTime;
        },

        dateconvert(t)
        {
            if (t != null){
                var unixtimestamp = t;
                var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var date = new Date(unixtimestamp);
                var year = date.getFullYear();
                var month = months_arr[date.getMonth()];
                var day = date.getDate();
                var hours = date.getHours();
                var minutes = "0" + date.getMinutes();
                var seconds = "0" + date.getSeconds();
                var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            }

            if (t == ''){
                var convdataTime = '';
            }

            return convdataTime;
        },

        //redundant for now. but can use later if required
        mySortCompare(a, b, key) {
            //console.log(key)
            if (key === 'createDate') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key];
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false;
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listAllTxn(1);
          }, 800);
        },

        onConfirm(value){
            console.log(value);
            this.createLane(value);
            this.$root.$emit('bv::hide::modal', 'modalInfo');
        },

        onConfirmEdit(value){
            console.log(value);
            this.updateLane(value);
            this.$root.$emit('bv::hide::modal', 'modalUpdate');
        }
    }
}
</script>

<style scoped>
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
</style>