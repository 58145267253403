<template>
<b-row>
    <b-col cols="12">
      <transition name="slide">
        <b-card>
            <b-row>
                <b-col class="text-left">
                <h5 class="mb-3 text-uppercase text-grey">Token Listing</h5>
                </b-col>
            </b-row>
            <b-tabs content-class="mt-3" v-model="tabIndex" @input="listAllCarPark" lazy>
                <b-tab title="Deleted Tokens">
                    <div style="display: block;
                    width: 100%;">
                    <b-form>
                        <b-row>
                            <b-col md="6" class="text-left">
                                <b-form-group label-cols="3" label="System">
                                    <v-select v-model="system_selected" placeholder="-- All --" :options="system" :disabled="disabledSys"></v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                        prev-text="Prev"
                        next-text="Next"
                        hide-goto-end-buttons
                    >
                    </b-pagination>
                    <b-table hover 
                    :items="items"
                    :fields="valfieldsD"
                    show-empty
                    small
                    responsive
                    bordered
                    striped
                    :busy="isBusy"
                    :filter="filter"
                    :per-page="0"
                    :current-page="currentPage"
                    >
                    <template v-slot:table-busy>
                        <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-2"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </template>
                    <template v-slot:cell(sys)="row">
                        <div class="text-center my-2">
                        {{row.item.systemID == 1 ? "ParkAide" : "SnatchPark"}}
                        </div>
                    </template>
                    <template v-slot:cell(createDate)="row">
                        <div class="text-center my-2">
                        {{Unix_timestamp(row.item.createDate)}}
                        </div>
                    </template>
                    <template v-slot:cell(deleteDate)="row">
                        <div class="text-center my-2">
                        {{Unix_timestamp(row.item.deleteDate)}}
                        </div>
                    </template>
                    </b-table>
                    </div>
                </b-tab>
                <b-tab title="Active Tokens" active>
                    <div style="display: block;
                    width: 100%;">
                    <b-form>
                        <b-row>
                            <b-col md="6" class="text-left">
                                <b-form-group label-cols="3" label="System">
                                    <v-select v-model="system_selected" placeholder="-- All --" :options="system" :disabled="disabledSys"></v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                        prev-text="Prev"
                        next-text="Next"
                        hide-goto-end-buttons
                    >
                    </b-pagination>
                    <b-table hover 
                    :items="items"
                    :fields="valfields"
                    show-empty
                    small
                    responsive
                    bordered
                    striped
                    :busy="isBusy"
                    :filter="filter"
                    :per-page="0"
                    :current-page="currentPage"
                    >
                    <template v-slot:cell(actions)="row">
                    <b-button v-b-tooltip.hover title="Reset Token" pill size="sm" variant="success" @click="info(row.item, row.index, $event.target, 'Reset')" class="mr-1">
                    <i class="fa fa-undo"></i>
                    </b-button>
                    <b-button v-b-tooltip.hover title="Edit Token" pill size="sm" variant="secondary" @click="infoEdit(row.item, row.index, $event.target, 'Edit')" class="mr-1">
                    <i class="fa fa-edit"></i>
                    </b-button>
                    <b-button v-b-tooltip.hover title="Delete Token" pill size="sm" variant="danger" @click="info(row.item, row.index, $event.target, 'Delete')" class="mr-1">
                        <i class="fa fa-trash"></i>
                    </b-button>
                    </template>
                    <template v-slot:table-busy>
                        <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-2"></b-spinner>
                        <strong>Loading...</strong>
                        </div>
                    </template>
                    <template v-slot:cell(sys)="row">
                        <div class="text-center my-2">
                        {{row.item.systemID == 1 ? "ParkAide" : "SnatchPark"}}
                        </div>
                    </template>
                    <template v-slot:cell(createDate)="row">
                        <div class="text-center my-2">
                        {{Unix_timestamp(row.item.createDate)}}
                        </div>
                    </template>
                    <template v-slot:cell(updateDate)="row">
                        <div class="text-center my-2">
                        {{Unix_timestamp(row.item.updateDate)}}
                        </div>
                    </template>
                    </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
      </transition>
      <b-modal id="modalUpdate" size="lg" title="Update Token Listing" hide-footer backdrop no-close-on-backdrop>
      <!-- <pre>{{ modalInfo.content }}</pre> -->
      <v-update :modalUpdate="modalUpdate" @confirm="onConfirmEdit"></v-update>
    </b-modal>
    </b-col>
</b-row>
</template>

<script>
import timer from '../assets/js/sleeper';
import vSelect from 'vue-select';
import UpdateTokenListing from './UpdateTokenListing'

let items = [];
export default {
    data() {
      return {
        items: [],
        allcarpark: [],
        isBusy: false,
        sortDirection: 'asc',
        filter: null,
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        tabIndex: 0,
        system: [],
        disabledSys: false,
        system_selected: null,
        valfields: [
        { key: 'row', label: '#', class: 'text-nowrap' },
        { key: 'actions', label: 'Actions', class: 'text-nowrap' },
        { key: 'sys', label: 'System', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'carparkID', label: 'CP ID', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'carParkName', label: 'CP Name', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'tokenID', label: 'Token ID', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'name', label: 'Token Name', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'token', label: 'Token', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'createDate', label: 'Date Created', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'updateDate', label: 'Date Updated', sortable: true, sortDirection: 'desc', class: 'text-nowrap' }
      ],

        valfieldsD: [
            { key: 'row', label: '#', class: 'text-nowrap' },
            { key: 'sys', label: 'System', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'carparkID', label: 'CP ID', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'carParkName', label: 'CP Name', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'tokenID', label: 'Token ID', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'name', label: 'Token Name', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'token', label: 'Token', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'createDate', label: 'Date Created', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
            { key: 'deleteDate', label: 'Date Deleted', sortable: true, sortDirection: 'desc', class: 'text-nowrap' }
        ],

        sort: 'createDate',
        editTokenName: '',
        modalInfo: { title: '', content: '{}', name: '' },
        modalUpdate: { title: '', content: '{}', name: '' },
      }
    },
    
    components:{
        'v-select': vSelect,
        'v-update': UpdateTokenListing
    },

    async created(){
        await this.listAllCarPark();
        await this.listSystem();
        await this.listAllTokens()
    },

    watch: {
        tabIndex: {
        handler: function(value) {
            this.items = [];
            this.currentPage = 1;
            this.totalItems = 0
        }
        },

        currentPage: {
        handler: function(value) {
            if(this.items.length > 0)
            this.listAllTokens();
        }
        },

        system_selected: {
        handler: function(value) {
            this.listAllTokens();
        }
        }
    },

    methods: {
        listAllTokens(){
            if (this.currentPage >= 0 ){
            this.isBusy = true;
            let sys_selected = this.system_selected != null ? this.system_selected.code : "";
            //console.log(sys_selected);

            if (this.tabIndex == 0){
                this.sort = 'deleteDate';
            }

            if (this.tabIndex == 1){
                this.sort = 'createDate';
            }

            this.$store.dispatch("getAllTokens", {curPage: this.currentPage, active: this.tabIndex, system_selected: sys_selected, sort: this.sort})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            let dt = response.data.result
                            this.isBusy = !this.isBusy

                            for(var i=0; i<dt.length; i++){
                              dt[i].row = i + 1;

                              let b = this.allcarpark.find((item) => {
                                  return item.carparkID == dt[i].carparkID;
                              });

                              let c = this.system.find((item) => {
                                    return item.code == dt[i].systemID;
                              });

                              dt[i].systemLabel = c.label;

                              dt[i].carParkName = b.carpark;
                            }

                            this.items = dt;

                            this.totalItems = response.data.count;
                        }
                    })
                    .catch(error => {
                    });
            }
        },

        async listAllCarPark(){
            try{
                let response = await this.$store.dispatch("getAllCarPark", {})
                if (response){
                    //this.system.push(response.data.result);
                    let dt = response.data.result;
                    this.allcarpark = dt;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        listSystem(){
            this.disabledSys = true;
            this.$store.dispatch("getSystem", {})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].systemID, text: dt[i].name});
                              ndt.push({code: dt[i].systemID, label: dt[i].systemID + ' - ' +dt[i].name});
                            }

                            this.system = ndt;
                            this.disabledSys = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        infoEdit(item, index, button) {
            this.modalUpdate.title = `Row index: ${index}`
            this.modalUpdate.content = JSON.stringify(item)

            this.modalUpdate.system = JSON.stringify(this.system);

            let ndt = [];
            let dt = this.allcarpark;
            for(var i=0; i<dt.length; i++){
                //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
            }
            this.modalUpdate.carpark = JSON.stringify(ndt);

            this.$root.$emit('bv::show::modal', 'modalUpdate', button)
        },

        info(item, index, button, typ) {
            //console.log(item.tokenID);
            /*
            this.$swal({
                title: typ + ' Car Park Token',
                text: "Are you sure you want to "+typ.toLowerCase()+" car park token id " + item.tokenID + "?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, please!'  
            }).then((result) => {
                if (result.value){
                    if (typ == 'Reset')
                    this.rstToken(item.tokenID);

                    if (typ == 'Edit')
                    this.editToken(item);

                    if (typ == 'Delete')
                    this.delToken(item.tokenID);

                this.$swal({
                    icon : 'success',
                    title : 'Token '+typ+'!'
                })
                }
            })
            */

            if (typ == 'Edit'){
            this.$swal({
                    title: 'Edit Token Name',
                    input: 'text',
                    inputValue: item.name,
                    showCancelButton: true,
                    inputValidator: (value) => {
                    if (!value) {
                        return 'You need to write something!'
                    }
                    }
                    })
                        
                    .then((result) => {
                    if (result.value){
                        console.log(result.value);
                        this.editToken(item, result.value);

                        this.$swal({
                            icon : 'success',
                            title : 'Token '+typ+'!'
                        })
                    }
                })
            }

            else{
                this.$swal({
                title: typ + ' Car Park Token',
                text: "Are you sure you want to "+typ.toLowerCase()+" car park token id " + item.tokenID + "?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, please!'  
            }).then((result) => {
                if (result.value){
                    if (typ == 'Reset')
                    this.rstToken(item.tokenID);

                    if (typ == 'Delete')
                    this.delToken(item.tokenID);

                this.$swal({
                    icon : 'success',
                    title : 'Token '+typ+'!'
                })
                }
            })
            }
        },

        onConfirmEdit(value){
            console.log(value);
            this.editToken(value);
            this.$root.$emit('bv::hide::modal', 'modalUpdate');
        },

        rstToken(token){

            this.$store.dispatch("resetToken", {tokenID: token})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //console.log('done');
                            this.listAllTokens();
                        }
                    })
                    .catch(error => {
                        
                    });
        },

        delToken(token){

            this.$store.dispatch("deleteToken", {tokenID: token})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //console.log('done');
                            this.listAllTokens();
                        }
                    })
                    .catch(error => {
                        
                    });
        },

        editToken(v){
            this.$store.dispatch("updateToken", {tokenID: v.tokenID, name: v.name, system: v.system, carpark: v.carpark})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //console.log('done');
                            this.listAllTokens();
                        }
                    })
                    .catch(error => {
                        
                    });
        },

        Unix_timestamp(t)
        {
            if (t != null){
                var unixtimestamp = t;
                var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var date = new Date(unixtimestamp*1000);
                var year = date.getFullYear();
                var month = months_arr[date.getMonth()];
                var day = date.getDate();
                var hours = date.getHours();
                var minutes = "0" + date.getMinutes();
                var seconds = "0" + date.getSeconds();
                var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            }

            if (t == null){
                var convdataTime = '';
            }

            return convdataTime;
        },

        //redundant for now. but can use later if required
        mySortCompare(a, b, key) {
            //console.log(key)
            if (key === 'createDate') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key];
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false;
            }
        }
    }
}
</script>

<style scoped>
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
</style>