<template>
<b-row>
    <b-col cols="12">
      <transition name="slide">
        <b-card>
          <b-row>
            <b-col class="text-left">
              <h5 class="mb-3 text-uppercase text-grey">Token Generator</h5>
            </b-col>
          </b-row>
        <b-form @submit="createCarparkToken" @reset="onReset">
          <b-row>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="System">
                      <!--
                      <b-form-select v-model="selected" :options="system" @change="listCarParkBySystem" :disabled="disabledSys">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Select a System --</b-form-select-option>
                            </template>
                      </b-form-select>
                      -->
                      <v-select v-model="selected" placeholder="-- Select a System --" :options="system" @input="listCarParkBySystem" :disabled="disabledSys"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Car Park">
                      <!--
                      <b-form-select v-model="selectedCP" :options="carpark" :disabled="disabledCP">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Select a Car Park --</b-form-select-option>
                            </template>
                      </b-form-select>
                      -->
                    <v-select v-model="selectedCP" placeholder="-- Select a Car Park --" :options="carpark" :disabled="disabledCP"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Token Name">
                        <b-form-input v-model="token_name"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="12" class="text-left">
                  <b-button-group>
                    <b-button type="submit" variant="primary"><i class="fa fa-barcode"></i> Generate Token</b-button>
                    <b-button variant="primary" @click="listGeneratedCarparkTokenDetails()"><i class="fa fa-search"></i> View Tokens</b-button>
                    <b-button type="reset" variant="danger"><i class="fa fa-undo"></i> Reset Fields</b-button>
                  </b-button-group>
              </b-col>
          </b-row>
        </b-form>
          <b-row>
              <b-col md="12" class="mt-4">
                  <b-card bg-variant="light" header="Result" class="text-center">
                    <transition name="fade">
                        <div class="spinner-container" v-if="loading">
                            <b-spinner label="Spinning" style="position: relative; top: 45%;"></b-spinner>
                        </div>
                    </transition>
                    <b-card-text>
                        <b-row>
                            <b-col md="12" v-if="!generated_details.length && token_count == null">
                                <h4>Please fill in details to generate a token for a car park</h4>
                            </b-col>
                            <b-col md="12" v-if="!generated_details.length && token_count == 0">
                                <h4>No Token found. Please generate one if required</h4>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Name" label-cols="3" v-if="generated_details.length">
                                    <b-input-group>
                                            <b-form-input :value="generated_details[0].name" disabled></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Token" label-cols="3" v-if="generated_details.length">
                                    <b-input-group>
                                            <b-form-input :value="generated_details[0].token" readonly id="token_value"></b-form-input>
                                            <b-input-group-append>
                                                <b-button variant="info" @click="copyClip('token_value')" id="popover-button-variant">Copy</b-button>
                                            </b-input-group-append>
                                    </b-input-group>
                                    <b-popover placement="bottom" target="popover-button-variant" variant="success" triggers="click">
                                        <template v-slot:title>Success</template>
                                        Token Copied!
                                    </b-popover>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Token ID" label-cols="3" v-if="generated_details.length">
                                    <b-input-group>
                                            <b-form-input :value="generated_details[0].tokenID" readonly id="token_id"></b-form-input>
                                            <b-input-group-append>
                                                <b-button variant="info" @click="copyClip('token_id')" id="popover-button-variant">Copy</b-button>
                                            </b-input-group-append>
                                    </b-input-group>
                                    <b-popover placement="bottom" target="popover-button-variant" variant="success" triggers="click">
                                        <template v-slot:title>Success</template>
                                        Token ID Copied!
                                    </b-popover>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                  </b-card>
              </b-col>
          </b-row>


        </b-card>
      </transition>
    </b-col>
    <!--
    <b-col cols="12">
      <b-card>
        <vue-editor ></vue-editor>
      </b-card>
    </b-col>
    -->
    <!-- Info modal -->
    
  </b-row>
</template>

<script>
import timer from '../assets/js/sleeper';

import vSelect from 'vue-select'

export default {
    data(){
        return{
            system: [],
            //system_name: '',
            carpark: [],
            token_name: '',
            selected: null,
            selectedCP: null,
            generated_details: [],
            loading: false,
            token_count: null,
            disabledSys: true,
            disabledCP: true
        }
    },

    components: {
        'v-select': vSelect
    },

    created(){
        this.listSystem();
    },

    computed:{
        
    },

    methods: {
        listSystem(){
            this.$store.dispatch("getSystem", {})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].systemID, text: dt[i].name});
                              ndt.push({code: dt[i].systemID, label: dt[i].systemID + ' - ' +dt[i].name});
                            }

                            this.system = ndt;
                            this.disabledSys = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        listCarParkBySystem(value){
            var cd = value != null ? value.code : '';
            this.resetSearch();

            this.$store.dispatch("getCarPark", {sel_system: cd})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
                            }

                            //this.system_name = this.system.filter(sys => sys.value === this.selected)[0].text;

                            this.carpark = ndt;
                            
                            if (response.data.count > 0)
                                this.disabledCP = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        createCarparkToken(evt){
            evt.preventDefault();
            
            this.loading = true;
            
            let rq = '';

            let cdSys = this.selected != null ? this.selected.code : null;
            let cdCp = this.selectedCP != null ? this.selectedCP.code : null;

            if (this.token_name.length > 0)
                rq = {systemID: cdSys, carparkID: cdCp, name: this.token_name};

            else
                rq = {systemID: cdSys, carparkID: cdCp};

            if (typeof cdSys != null && typeof cdCP != null && this.token_name.length > 0)
            {
                this.$swal({
                title: 'Create Car Park Token',
                text: "Are you sure you want to generate a car park token for "+this.selectedCP.label+" from "+this.selected.label+"?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, please!'
            }).then((result) => {
                if (result.value){
                    this.$store.dispatch("genCarparkToken", rq)
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //console.log('done');
                            let flag = true;
                            this.token_name = "";
                            this.listGeneratedCarparkTokenDetails(flag);
                        }
                    })
                    .catch(error => {
                        //console.log(JSON.parse(error.response.request.response).message)
                        if (JSON.parse(error.response.request.response).message == 'Already Exist'){
                            this.$bvToast.toast('Token already exists for this car park!', {
                            title: `Notice`,
                            variant: 'warning',
                            solid: true
                            })
                        }

                        if (JSON.parse(error.response.request.response).message == 'System Error' || JSON.parse(error.response.request.response).message == 'missing name'){
                            this.$bvToast.toast('Please input necessary details to generate token', {
                            title: `Error`,
                            variant: 'danger',
                            solid: true
                            })
                        }
                        this.loading = false;
                    });
                }
            })
            }

            else{
                this.$bvToast.toast('Please input all details above to generate a carpark token', {
                title: `Error`,
                variant: 'danger',
                solid: true
                })

                this.loading = false;
            }
        },

        listGeneratedCarparkTokenDetails(f){
            if (f != true){
                this.loading = true;
            }

            //console.log(this.selected)

            var cd = this.selected != null ? this.selected.code : '';
            var cdCp = this.selectedCP != null ? this.selectedCP.code : '';

            this.$store.dispatch("getGeneratedCarparkTokenDetails", {systemID: cd, carparkID: cdCp})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            //console.log(dt);
                            this.generated_details = dt;
                            this.loading = false;
                            this.token_count = response.data.count;

                            if(f == true){
                                this.$bvToast.toast('Token generated!', {
                                title: `Success`,
                                variant: 'success',
                                solid: true
                                })
                            }

                            if (f != true){
                                this.loading = false;
                            }
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        this.generated_details = [];
                        this.token_count = null;
                        //console.log(JSON.parse(error.response.request.response).message)
                        if (JSON.parse(error.response.request.response).message == 'Not Found'){
                            this.$bvToast.toast('Please input necessary details to view token', {
                            title: `Error`,
                            variant: 'danger',
                            solid: true
                            })
                        }
                    });
        },

        copyClip(id){
            let copyTxt = document.getElementById(id);

            copyTxt.select();
            copyTxt.setSelectionRange(0,99999);

            document.execCommand("copy");
        },

        resetSearch(){
            //this.selectedSystem = '';
            this.disabledCP = true;
            this.carpark = [];
            this.selectedCP = '';
        },

        onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.token_name = '';
        this.carpark = [];
        this.disabledCP = true;
        this.selectedCP = '';
        this.selected = '';
      }
    }
}
</script>