<template>
<b-row>
    <b-col cols="12">
      <transition name="slide">
        <b-card>
            <b-row>
                <b-col class="text-left">
                <h5 class="mb-3 text-uppercase text-grey">Transactional Listing</h5>
                </b-col>
            </b-row>
            <div style="display: block;
    width: 100%;">
        <b-form>
          <b-row>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="System">
                      <v-select v-model="selected" placeholder="-- All --" :options="system" @input="listCarParkBySystem(selected, 1)" :disabled="disabledSys"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Car Park">
                    <v-select v-model="selectedCP" placeholder="-- All --" :options="carpark" @input="listTokenByCarPark(selectedCP, 1)" :disabled="disabledCP"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Transaction">
                    <v-select v-model="selectedSync" placeholder="-- All --" :options="syncOptions" @input="listAllTxn(1)"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="6" class="text-left">
                  <b-form-group label-cols="3" label="Token">
                    <v-select v-model="selectedTK" placeholder="-- All --" :options="tokens" @input="listAllTxn(1)" :disabled="disabledTK"></v-select>
                  </b-form-group>
              </b-col>
              <b-col md="12">
                  <b-row>
                      <b-col md="6" class="text-left">
                            <vc-date-picker
                                :disabled-dates='dayplusone'
                                :key="counter1" v-model="starttime.item" mode="datetime" is-range update-on-input :popover="{visibility: 'click'}" @drag="cleardayplusone($event)" @input="listAllTxn(1)" :columns="$screens({ default: 1, lg: 2 })" locale="en-GB">
                                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                                    <b-form-group label-cols="3" label="From Date - To Date">
                                        <b-form-input
                                            @click="togglePopover({ placement: 'bottom-start' })"
                                            :value="inputValue.start != null ? `${inputValue.start} - ${inputValue.end}` : ''"
                                            @keyup="starttime.item.start = ''; starttime.item.end = ''"
                                            dense
                                            v-on="inputEvents"
                                            hide-details="auto"
                                            outlined
                                            autocomplete="off"
                                            hint="You can only select date range not more than 1 month."
                                            persistent-hint
                                        ></b-form-input>
                                    </b-form-group>
                                    </template>
                                </vc-date-picker>
                        </b-col>
                        <b-col md="6" class="text-left">
                            <b-form-group label-cols="3" label="Search">
                                    <b-form-input v-model="txn_search" @keyup="searchTimeOut"></b-form-input>
                            </b-form-group>
                        </b-col>
                  </b-row>
              </b-col>
          </b-row>
        </b-form>
            <b-pagination
                v-model="currentPage"
                :total-rows.sync="totalItems"
                :per-page="perPage"
                prev-text="Prev"
                next-text="Next"
                class="mt-4"
            >
            </b-pagination>
            <b-row>
                <b-col md="12" class="text-left">
                  <b-button-group>
                    <b-button type="submit" variant="primary" @click="syncTrxn"><i class="fa fa-sync"></i> Synchronize Transaction</b-button>
                  </b-button-group>
              </b-col>
            </b-row>
            <b-table hover 
            class="mt-3"
            :items="items"
            :fields="valfields"
            show-empty
            small
            responsive
            bordered
            striped
            :busy="isBusy"
            :filter="filter"
            :per-page="0"
            :current-page="currentPage"
            >
            <template v-slot:cell(actions)="row">
              <b-button v-b-tooltip.hover title="View Details" pill size="sm" variant="success" @click="info(row.item, row.index, $event.target)" class="mr-1">
               <i class="fa fa-search"></i>
              </b-button>
            </template>
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
                </div>
            </template>
            <template v-slot:cell(EntryTime)="row">
                <div class="text-center">
                {{row.item.EntryTime}}
                </div>
            </template>
            <template v-slot:cell(ExitTime)="row">
                <div class="text-center">
                {{row.item.ExitTime}}
                </div>
            </template>
            <template v-slot:cell(createDate)="row">
                <div class="text-center">
                {{row.item.createDate}}
                </div>
            </template>
            <template v-slot:cell(syncDate)="row">
                <div class="text-center">
                {{row.item.syncDate}}
                </div>
            </template>
            <template v-slot:cell(MfgNo)="row">
                <div class="text-center">
                {{row.item.MfgNo == null ? "NA" : row.item.MfgNo}}
                </div>
            </template>
            <template v-slot:cell(CardNo)="row">
                <div class="text-center">
                {{row.item.CardNo == null ? "NA" : row.item.CardNo}}
                </div>
            </template>
            <template v-slot:cell(FareSST)="row">
                <div class="text-center">
                {{row.item.FareSST == null ? 0 : row.item.FareSST}}
                </div>
            </template>
            </b-table>
            </div>
        </b-card>
      </transition>
    </b-col>
    <b-modal id="modalInfo" size="lg" title="Transaction Detail" hide-footer>
      <!-- <pre>{{ modalInfo.content }}</pre> -->
      <v-txn-det :modalInfo="modalInfo"></v-txn-det>
    </b-modal>
</b-row>
</template>

<script>
import timer from '../assets/js/sleeper';
import vSelect from 'vue-select'
import ViewTransactionDetails from './ViewTransactionDetails'
import * as moment from "moment";

let items = [];
export default {
    data() {
      return {
        items: [],
        isBusy: false,
        filter: null,
        currentPage: 1,
        perPage: 100,
        totalItems: 0,
        /*
        ID, Token ID, CP ID, Car Park, Mfg No., Card No., Method, Entry Lane, Entry Date, Exit Lane, Exit Date,  Amount, Created Date, Sync Date
        */
        valfields: [
        { key: 'row', label: '#', class: 'text-nowrap' },
        { key: 'actions', label: 'Actions', class: 'text-nowrap' },
        { key: 'trxnID', label: 'ID', class: 'text-nowrap' },
        { key: 'tokenID', label: 'Token ID', class: 'text-nowrap' },
        //{ key: 'tokenName', label: 'Token', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'carparkID', label: 'CP ID', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'carParkName', label: 'Car Park', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'MfgNo', label: 'Mfg No.', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'CardNo', label: 'Card No.', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'remark', label: 'Method', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'EntryLane', label: 'Entry Lane', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'EntryTime', label: 'Entry Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'ExitLane', label: 'Exit Lane', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'ExitTime', label: 'Exit Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'Fare', label: 'Amount', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'FareSST', label: 'Amount (SST)', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        //{ key: 'netAmount', label: 'Net Amount', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'createDate', label: 'Created Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' },
        { key: 'syncDate', label: 'Sync Date', sortable: true, sortDirection: 'desc', class: 'text-nowrap' }
      ],
      system: [],
      carpark: [],
      tokens: [],
      allcarpark: [],
      selected: '',
      selectedCP: '',
      selectedTK: '',
      disabledSys: true,
      disabledCP: true,
      disabledTK: false,
      modalInfo: { title: '', content: '{}', name: '' },
      syncOptions: [
                    {code: "1", label: "Not Synchronized"}
                   ],
      selectedSync: null,
      txn_search: "",
      timeout: null,
      sort: 'createDate',
      starttime: {item: {start: '', end: ''}},
      endtime: {item: ''},
      starttimeforapi: '',
      endtimeforapi: '',
      dayplusone: [],
      show: false,
      counter1: 0,
      }
    },

    components: {
        'v-select': vSelect,
        'v-txn-det': ViewTransactionDetails
    },

    async created(){
        await this.listAllCarPark();
        await this.listSystem();
        this.counter1++;
        //this.listAllTokens();
        //this.listAllTxn();
    },

    watch: {
        currentPage: {
        handler: function(value) {
            if (this.currentPage != 1)
            {
                this.items = [];
                this.listAllTxn();
            }
        }
        },
    },

    methods: {
        listSystem(){
            this.$store.dispatch("getSystem", {})
                    .then(this.listAllCarPark()).then(this.listAllTokens()).then(this.listAllTxn()).then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].systemID, text: dt[i].name});
                              ndt.push({code: dt[i].systemID, label: dt[i].systemID + ' - ' +dt[i].name});
                            }

                            this.system = ndt;
                            this.disabledSys = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        listAllTokens(){
            this.$store.dispatch("getAllTokens", {sort: this.sort})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];
                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].tokenID, label: dt[i].tokenID + ': ' + dt[i].name, tokenID: dt[i].tokenID});
                            }
                            this.tokens = ndt;
                        }
                    })
                    .catch(error => {
                    });
        },

        listAllCarPark(){
            this.disabledCP = true;
            this.$store.dispatch("getAllCarPark", {})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            this.allcarpark = dt;

                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
                            }

                            this.carpark = ndt;
                            this.disabledCP = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        async listAllTxn(pg){
            if (this.currentPage >= 0 ){
            this.isBusy = true;
            var isSync = this.selectedSync != null ? this.selectedSync.code : "";

            await this.setTime();
            this.$store.dispatch("getAllTxn", {curPage: pg > 0 ? this.currentPage = pg : this.currentPage, systemID: this.selected == null ? "" : this.selected.code, carparkID: this.selectedCP == null ? "" : this.selectedCP.code, notSync: isSync, search: this.txn_search, sort: this.sort, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, tokenID: this.selectedTK == null ? "" : this.selectedTK.code})
                    .then(timer.sleeper(300)).then(response => {
                        if (response){
                            let dt = response.data.result
                            this.isBusy = !this.isBusy

                            for(var i=0; i<dt.length; i++){
                              dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;

                              let a = this.tokens.find((item) => {
                                  return item.tokenID == dt[i].tokenID;
                              });

                              let b = this.allcarpark.find((item) => {
                                  return item.carparkID == dt[i].carparkID;
                              });

                              if (typeof a == 'undefined'){
                                  //this.$bvToast.toast('Table cannot load due to one or more columns unable to match with deleted data', {
                                  //  title: `Warning`,
                                  //  variant: 'warning',
                                  //  solid: true
                                  //  })

                                    dt[i].tokenName = "DELETED TOKEN";
                              }

                              else{
                                  dt[i].tokenName = a.name;
                              }

                              dt[i].carParkName = b.carpark;

                              let fare = dt[i].Fare == null ? 0 : dt[i].Fare;
                              let faresst = dt[i].FareSST == null ? 0 : dt[i].FareSST;

                              dt[i].netAmount = fare - faresst;
                              dt[i].createDate = dt[i].createDate == null ? "N/A" : moment.unix(dt[i].createDate).format('DD-MM-YYYY hh:mm:ss A');
                              dt[i].syncDate = dt[i].syncDate == null ? "N/A" : moment.unix(dt[i].syncDate).format('DD-MM-YYYY hh:mm:ss A');
                              dt[i].EntryTime = dt[i].EntryTime == null ? "N/A" : moment(dt[i].EntryTime).format('DD-MM-YYYY hh:mm:ss A');
                              dt[i].ExitTime = dt[i].ExitTime == null ? "N/A" : moment(dt[i].ExitTime).format('DD-MM-YYYY hh:mm:ss A');
                            }

                            this.items = dt;

                            this.totalItems = response.data.count;
                        }
                    })
                    .catch(error => {
                    });
            }
        },

        info(item, index, button) {
            this.modalInfo.title = `Row index: ${index}`
            this.modalInfo.content = JSON.stringify(item)
            this.$root.$emit('bv::show::modal', 'modalInfo', button)
        },

        listCarParkBySystem(value, pg){
            var cd = value != null ? value.code : '';
            this.resetSearch();

            //console.log(cd)
            this.$store.dispatch("getCarPark", {sel_system: cd})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].carparkID, label: dt[i].carparkID + ' - ' + dt[i].carpark});
                            }

                            //this.system_name = this.system.filter(sys => sys.value === this.selected)[0].text;

                            this.listAllTxn(pg);

                            this.carpark = ndt;

                            if (response.data.count > 0)
                                this.disabledCP = false;
                        }
                    })
                    .catch(error => {
                    });
        },

        listTokenByCarPark(value, pg){
            var cd = value != null ? value.code : '';
            this.tokens = [];
            this.selectedTK = '';
            this.disabledTK = true;

            //console.log(cd)
            this.$store.dispatch("getAllTokens", {carpark_selected: cd})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //this.system.push(response.data.result);
                            let dt = response.data.result;
                            let ndt = [];

                            for(var i=0; i<dt.length; i++){
                              //ndt.push({value: dt[i].carparkID, text: dt[i].carpark});
                                ndt.push({code: dt[i].tokenID, label: dt[i].tokenID + ': ' + dt[i].name, tokenID: dt[i].tokenID});
                            }

                            //this.system_name = this.system.filter(sys => sys.value === this.selected)[0].text;

                            this.listAllTxn(pg);

                            this.tokens = ndt;
                        }

                        this.disabledTK = false;
                    })
                    .catch(error => {
                    });
        },

        syncTrxn(){
            this.$store.dispatch("syncTrans", {})
                    .then(timer.sleeper(800)).then(response => {
                        if (response){
                            //console.log('done');
                            this.listAllTxn();

                            this.$bvToast.toast('All Transactions have been synchronized', {
                            title: `Success`,
                            variant: 'success',
                            solid: true
                            })
                        }
                    })
                    .catch(error => {
                        this.$bvToast.toast('Something went wrong. Please try again later', {
                            title: `Not Successful`,
                            variant: 'danger',
                            solid: true
                            })
                    });
        },

        resetSearch(){
            //this.selectedSystem = '';
            this.disabledCP = true;
            this.carpark = [];
            this.selectedCP = '';
        },

        Unix_timestamp(t)
        {
            if (t != null){
                var unixtimestamp = t;
                var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var date = new Date(unixtimestamp*1000);
                var year = date.getFullYear();
                var month = months_arr[date.getMonth()];
                var day = date.getDate();
                var hours = date.getHours();
                var minutes = "0" + date.getMinutes();
                var seconds = "0" + date.getSeconds();
                var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            }

            if (t == null){
                var convdataTime = '';
            }

            return convdataTime;
        },

        dateconvert(t)
        {
            if (t != null){
                var unixtimestamp = t;
                var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var date = new Date(unixtimestamp);
                var year = date.getFullYear();
                var month = months_arr[date.getMonth()];
                var day = date.getDate();
                var hours = date.getHours();
                var minutes = "0" + date.getMinutes();
                var seconds = "0" + date.getSeconds();
                var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            }

            if (t == ''){
                var convdataTime = '';
            }

            return convdataTime;
        },

        //redundant for now. but can use later if required
        mySortCompare(a, b, key) {
            //console.log(key)
            if (key === 'createDate') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key];
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false;
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listAllTxn(1);
          }, 800);
        },

        setTime(){
            let dte = new Date();
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss');
            let endtime = this.starttime.item.end !== '' ? moment(new Date(this.starttime.item.end)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date().setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss');
            if (this.starttime.item.start == ""){
                console.log('in');
                this.starttime.item.start = new Date(moment(starttime).subtract(0, 'days'));
                this.starttime.item.end = new Date(moment(endtime));
            }
            console.log('starttime', starttime);
            console.log('endtime', endtime);
            this.starttimeforapi = moment(starttime).unix();
            this.endtimeforapi = moment(endtime).unix();

            // Add a day
            let dte2 = new Date(moment(endtime).add(1, 'days'))
            console.log('dte', new Date(moment(endtime).add(1, 'days')));
            this.dayplusone = null;
        },

        cleardayplusone(v){
            let dte2 = new Date(moment(v.start).add(31, 'days'));
            this.dayplusone = [
                        {
                        start: dte2,
                        end: null
                        },
                    ];
        },
    }
}
</script>

<style scoped>
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
</style>